<script>
import { explainList, oddsExplain } from '@/api/home'

export default {
    name: 'GameRule',
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        var roomnum=this.$router.history.current.query.roomnum;
        var title=this.$router.history.current.query.title;
        var gametype=this.$router.history.current.query.gametype;
        return {
            mode:roomnum?'odds':'exlain',
            header: {
                mainTitle: roomnum?title: getWord('game_rules'),
            },
            type:JSON.parse(localStorage.getItem('game')).type,
            content:null,
            roomNumber:roomnum,
            gameType:gametype,
        }
    },
    props: [

    ],
    methods: {

    },
    mounted() {
        var that = this;
        if (this.mode==='exlain') {
            explainList(this.type).then(result => {
                if (result.data.code === 'SUCCESS') {
                    this.content=result.data.result.content
                }else if(result.data.code === 'FAILUE') {
                    app.Mint.Toast(result.data.msg);
                }
            })
        }else {
            oddsExplain(this.gameType, this.roomNumber).then(result => {
                if (result.data.code === 'SUCCESS') {
                    this.content = result.data.result.content;
                } else if (result.data.code == 'FAILUE') {                
                    app.Mint.Toast(result.data.msg);
                }
            })
        }
    },
    computed: {

    },
    inject: [

    ],
};
</script>
<template>
    <section id="game-rule" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
        />
        <content v-html="content"></content>
    </section>
</template>
<style lang='scss' scoped type="text/css">
#game-rule {
    height: 100%;
    overflow:auto;

    &.template-3 {

        content {
            display: block;
            padding: .25rem .5rem;
            font-size: .24rem;
        }
    }
}
</style>